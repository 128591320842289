/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Flex, FormControl, Grid, Image, Menu, MenuItem, MenuList, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Link, Box, useToast, Input, Alert, AlertDescription, AlertIcon, AlertTitle, Spinner } from '@chakra-ui/react'
import { FaCheck, FaClipboard, FaDownload, FaEye, FaEyeSlash, FaFileUpload, FaRegChartBar, FaSave } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { ComentariosContainer, InfoBox, Titulo } from './styles'
import { RadarChartJs } from './components/RadarChart'
import { Recomendacao } from './components/Recomendacao'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { iColaboradorBase, iCompetencia, iCompetenciaPDI, iEscala, iNomeId } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeAvaliadores.svg'
import PDFRadarChart from './components/RelatorioPDF/Components/RadarChartJs'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './components/RelatorioPDF'
import { ModalPDI } from './components/ModalPDI'
import { ComparativoChart } from './components/ComparativoChart'
import { parseJwt } from '../../../services/token'
import { DownloadImage, HSelect, PropInvalida } from '../../../Utils/Helper'
import { DashBox } from '../../../components/PerfilColaborador/styles'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ModalDataDevolutiva } from '../Resultado/Components/ModalData'
import { Tabela } from './components/Tabela'
import { ChartPercentual } from './components/ChartPercentual'
import { MdDownload } from 'react-icons/md'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import LoadingContainer from '../../../components/LoadingContainer'
import { AiFillTool } from 'react-icons/ai'
import { iAcao, TabRecomendacao } from './components/Recomendacoes'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { MenuButtonCmp } from '../../../components/MenuButtonCmp'
import { CheckboxCmp } from '../../../components/CheckboxCmp'
import { SwitchCmp } from '../../../components/SwitchCmp'
import { InputCmp } from '../../../components/InputCmp'
import { SelectCmp } from '../../../components/SelectCmp'
import { TabelaPDI } from './components/TabelaPDI'
import { PDIChart } from './components/PDIChart'
import { BoxPDI } from './components/BoxPDI'
import { RiBarChartHorizontalFill } from 'react-icons/ri'

export interface iDiscursiva {
  tipoAvaliador: number
  titulo: string
  texto: string
}

export interface IDadoGrafico {
  medias: number[]
  nome: string
}

export interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
  escala?: iEscala
  definicoesCompetencias?: string[]
}

export interface iComparativoRespostas {
  total: number
  melhor: number
}

export interface iCorTabela {
  eixo: string
  notasPositivas: number[]
  notasNegativas: number[]
  recomendacoes: number[]
}

export interface iLinhaTabela {
  [key: string]: any
  idQuestao: string
  competencia: string
  autoAvaliacao?: string
  gestor?: string
  pares?: string
  outros?: string
  liderados?: string
  consolidado?: string
  altalideranca?: string
  mediaSemAuto: string
  questoes: iLinhaTabela[]
}

export interface iResultadoIndividualResponse {
  pesquisaId: string
  avaliado: iColaboradorBase
  nomePesquisa: string
  totalAvaliadores: number
  totalRespostas: number
  mediaGeral: number
  competencias: iCompetencia[]
  participantes: number[]
  graficoHierarquia?: IGrafico
  graficosEscalasAlternativas?: iGraficosAlternativos[]
  graficosPDI?: IGrafico[]
  graficoInvertido?: IGrafico
  graficoIndividual?: IGrafico
  graficosPercentual?: IDadoGrafico[]
  discursivas: iDiscursiva[]
  recomendacoes: iCompetenciaPDI[]
  tabela: iLinhaTabela[]
  tabelasEscalasAlternativas?: iTabelasEscalasAlternativas[]
  tabelaPDI?: iLinhaTabela[]
  filtro: iNomeId[]
  comparativoId?: string
  pdf?: iPDF
  pdiNormal?: string
  pdiAvaliacao?: string
  liberarResultado: number
  cores: iCorTabela[]
  monitorarDevolutiva: boolean
  entregaDevolutiva: boolean
  dataDevolutiva?: string
  escala: iEscala
  escalaPDI?: iEscala
  boxPDI?: iBoxPDI[]
  is90Graus: boolean
  mudancaEixo: boolean
  adicionarPDI: boolean
  isResponsavel: boolean
  apenasAutoAvaliacao: boolean
  priorizadas: string[]
  acoes?: iAcao[]
}

export interface iGraficosAlternativos {
  hierarquia: IGrafico
  individual: IGrafico
}

export interface iTabelasEscalasAlternativas {
  tabela: iLinhaTabela[]
  escala?: iEscala
}

export interface iBoxPDI {
  avatar: string
  competencia: string
  status: number
  statusMensagem: string
  media: string
}

export interface iPDF {
  textoDeConclusao?: string
  competencias: iCompetencia[]
  melhorCompetencia: iCompetencia
  assinaturaGestor: boolean
  assinaturaColaborador: boolean
  mostrarTextoConclusao: boolean
  comentarios: boolean
}

export interface iResultadoComparativo {
  graficoComparativo?: IGrafico
  graficoComparativoAutoavaliacao?: IGrafico
}

export const ResultadoIndividual: React.FC = () => {
  const toast = useToast()
  const { avaliacaoId } = useParams<{ avaliacaoId: string }>()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const { cid } = parseJwt()
  const [RelatorioComparativo, setRelatorioComparativo] = useState(false)
  const [ModoEdicao, setModoEdicao] = useState(false)
  const [ModoEdicaoPrioridades, setModoEdicaoPrioridades] = useState(false)
  const [IsLoadingChart, setIsLoadingChart] = useState(false)
  const [ComentariosVisiveis, setComentariosVisiveis] = useState(false)

  const [Graficos, setGraficos] = useState<string[]>([])
  const [GraficosEscalasAlternativasHierarquia, setGraficosEscalasAlternativasHierarquia] = useState<Array<{ escala: iEscala, grafico: string }>>([])
  const [GraficosEscalasAlternativasIndividual, setGraficosEscalasAlternativasIndividual] = useState<Array<{ escala: iEscala, grafico: string }>>([])

  const [Grafico1, setGrafico1] = useState<string>()
  const [Grafico2, setGrafico2] = useState<string>()
  const [Grafico3, setGrafico3] = useState<string>()
  const [Grafico4, setGrafico4] = useState<string>()
  const [GraficosPDI, setGraficosPDI] = useState<string[]>()

  const [Model, setModel] = useState<iResultadoIndividualResponse>({
    graficosPercentual: [],
    pdiAvaliacao: '',
    cores: [],
    is90Graus: false,
    liberarResultado: 1,
    avaliado: {
      id: '',
      nome: ''
    },
    pdf: {
      mostrarTextoConclusao: true,
      melhorCompetencia: {
        discursiva: false,
        doSistema: false,
        icone: '',
        id: '',
        nome: '',
        questoes: []
      },
      competencias: [],
      assinaturaColaborador: false,
      assinaturaGestor: false,
      comentarios: false
    },
    discursivas: [],
    filtro: [],
    mediaGeral: 0,
    nomePesquisa: '',
    participantes: [],
    pesquisaId: '',
    recomendacoes: [],
    tabela: [],
    totalAvaliadores: 0,
    totalRespostas: 0,
    monitorarDevolutiva: false,
    entregaDevolutiva: false,
    escala: {
      id: '',
      fator: 0,
      nome: '',
      respostas: []
    },
    adicionarPDI: false,
    isResponsavel: false,
    priorizadas: [],
    competencias: [],
    mudancaEixo: false,
    apenasAutoAvaliacao: false
  })
  const [NotaMinima, setNotaMinima] = useState(0)
  const [NotaMaxima, setNotaMaxima] = useState(0)
  const [NotaMinimaFinal, setNotaMinimaFinal] = useState(0)
  const [NotaMaximaFinal, setNotaMaximaFinal] = useState(0)

  const [Comparativo, setComparativo] = useState<iResultadoComparativo>()

  const [TabGrafico, setTabGrafico] = useState<any>(0)
  const [GraficoComparativoAutoavaliacao, setGraficoComparativoAutoavaliacao] = useState(true)
  const [ModalDataOpen, setModalDataOpen] = useState(false)

  const [ModalPDIsOpen, setModalPDIsOpen] = useState(false)
  const [ComparativoRespostas, setComparativoRespostas] = useState<iComparativoRespostas>()

  const [AtualComparativo, setAtualComparativo] = useState<string>('')

  const [FiltroCompetencia, setFiltroCompetencia] = useState<string>('')
  const [FiltroTipo, setFiltroTipo] = useState<string>('')

  const [IsLoading, SetIsLoading] = useState(false)
  const [IsLoadingComparativo, setIsLoadingComparativo] = useState(false)
  const [IsLoadingIndividual, setIsLoadingIndividual] = useState(false)

  const colors = [
    'var(--Azul)',
    'var(--Green2)',
    'var(--Gray4)',
    'var(--Yellow)',
    'var(--Orange)'
  ]

  function getResultado(): void {
    SetIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/ResultadoIndividual`)
      .then(res => {
        setModel(res.data)
        setNotaMaxima(res.data.escala.fator)
        setNotaMaximaFinal(res.data.escala.fator)
        if (!res.data.apenasAutoAvaliacao && (res.data.acoes == null || res.data.acoes === undefined || res.data.acoes.length === 0)) {
          ObterPlanoAcao()
        } else {
          setTimeout(() => {
            SetIsLoading(false)
          }, 3200)
        }
      })
      .catch(err => console.log(err))
  }

  function CheckComentariosVisiveis(): void {
    appApi.get('Empresa/Comentarios')
      .then(res => {
        setComentariosVisiveis(res.data)
      })
      .catch(err => console.log(err))
  }

  function ObterPlanoAcao(): void {
    SetIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId}/PlanoAcao`)
      .then(res => {
        getResultado()
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Não foi possível gerar as recomendações por IA, recarregue a página ou tente novamente mais tarde!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .finally(() => SetIsLoading(false))
  }

  function FiltrarComaparativo(id: string): void {
    setAtualComparativo(id)
    setIsLoadingComparativo(true)
    setComparativo(undefined)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/Comparativo/?comparativoId=${id}`)
      .then(res => {
        setComparativo(res.data)
        setIsLoadingComparativo(false)
      })
      .catch(err => { console.log(err); setAtualComparativo(''); setRelatorioComparativo(false); setIsLoadingComparativo(false) })
  }

  function TemColuna(tipo: number): boolean {
    return Model.participantes.includes(tipo)
  }

  function CriarBasePDI(opcao: string): void {
    if (opcao === '1') {
      nav(`/Desempenho/PDI/Criar?responsavelId=${cid === Model.avaliado.id ? Model.avaliado.gestorId ?? cid : cid}&colaboradorId=${Model.avaliado.id}&avaliacaoId=${avaliacaoId ?? ''}`)
    } else {
      appApi.post(`PDI/${Model.pdiNormal ?? ''}/Avaliacao/${avaliacaoId ?? ''}/Continuar`)
        .then(() => nav(`/Desempenho/PDI/Editar/${Model.pdiNormal ?? ''}`))
        .catch(err => console.log(err))
    }
  }

  function onAlterarDevolutiva(Data: string | null, bool: boolean): void {
    const form = { dataDevolutiva: Data, devolutivaEntregue: bool }
    if (bool && (Data === '' || Data === null)) {
      toast({
        title: 'Preencha o campo de data',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId ?? ''}/devolutiva`, form)
      .then(() => { setModalDataOpen(false); getResultado() })
      .catch(err => console.log(err))
  }

  function AlterarVisibiliade(visibilidade: boolean): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId ?? ''}/AlterarVisibilidade?visibilidade=${visibilidade ? 0 : 1}`)
      .then(() => {
        setModel({ ...Model, liberarResultado: Model.liberarResultado === 0 ? 1 : 0 })
      })
      .catch(err => console.log(err))
  }

  function getRespostasMelhoradas(): void {
    let melhor = 0
    let total = Comparativo?.graficoComparativo?.dados[0].medias.length ?? 0
    if (Comparativo?.graficoComparativo) {
      for (let i = 0; i < Comparativo?.graficoComparativo.dados[0].medias.length; i++) {
        if (Comparativo?.graficoComparativo.dados[0].medias[i] < Comparativo?.graficoComparativo.dados[1].medias[i]) {
          melhor += 1
        }
        if (Comparativo?.graficoComparativo.dados[0].medias[i] === -1) {
          melhor = melhor - 1
          total = total - 1
        }
      }
    }
    setComparativoRespostas({ melhor: melhor, total: total ?? 0 })
  }

  function ChangeInputNota(value: string, type: string): void {
    if (type === 'max') {
      if (value === '') {
        setNotaMaxima(GetMaiorMenorNota('>'))
      } else if (!Model.graficoHierarquia?.dados.some(b => b.medias.some(a => a > parseFloat(value)))) {
        setNotaMaxima(parseFloat(value))
      } else {
        toast({
          title: 'Valor de limite máximo deve ser maior que a melhor nota do gráfico!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }
    }
    if (type === 'min') {
      if (value === '') {
        setNotaMinima(0)
      } else if (!Model.graficoHierarquia?.dados.some(b => b.medias.some(a => a < parseFloat(value)))) {
        setNotaMinima(parseFloat(value))
      } else {
        toast({
          title: 'Valor de limite mínimo deve ser menor que a pior nota do gráfico!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }
    }
  }

  function OnChangeGraficoComparativo(): void {
    setIsLoadingComparativo(true)
    setGraficoComparativoAutoavaliacao(!GraficoComparativoAutoavaliacao)
    setTimeout(() => {
      setIsLoadingComparativo(false)
    }, 1500)
  }

  function GetMaiorMenorNota(parameter: string): number {
    const Dados: number[] = []
    Model.graficoHierarquia?.dados.forEach(e => e.medias.forEach(a => Dados.push(a)))
    if (parameter === '>') {
      Dados.sort((a, b) => { return b - a })
      return Dados[0]
    } else {
      Dados.sort((a, b) => { return a - b })
      return Dados[0]
    }
  }

  function SalvarAlteracoes(): void {
    setModoEdicaoPrioridades(false)
    const data = {
      prioridades: Model.priorizadas
    }

    appApi.put(`AvaliacaoPesquisa/${avaliacaoId as string}/Salvar/Campos`, data)
      .then(() => { if (!Model.apenasAutoAvaliacao) { ObterPlanoAcao() } })
      .catch((err) => { console.log(err) })
  }

  function ObterMediaCompetencia(questoes: iLinhaTabela[]): number {
    let soma = 0
    questoes.filter(e => !isNaN(parseFloat((e.autoAvaliacao ?? '')))).forEach((e) => {
      soma += parseFloat(e.autoAvaliacao ?? '0')
    })
    return (soma / questoes.filter(e => e.autoAvaliacao).length)
  }

  function handleMedia(): number {
    let mediaAutoAval = 0

    Model.tabela.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(e.questoes)
      if (!isNaN(mediaCompetencia)) {
        mediaAutoAval += (mediaCompetencia < 0 ? 0 : mediaCompetencia)
      }
    })

    return mediaAutoAval / Model.tabela.filter(e => e.autoAvaliacao).length
  }

  function CalcularMediaGeral(tabela: iLinhaTabela[]): number {
    let mediaAutoAval = 0
    tabela.forEach((e): void => {
      const parse = parseFloat(e.mediaSemAuto.replace(',', '.') ?? '')
      mediaAutoAval = mediaAutoAval + (isNaN(parse) ? 0 : parse)
    })

    return mediaAutoAval / tabela.filter(e => e.autoAvaliacao).length
  }

  function GetGraficosEscalasAlternativas(): any {
    const graficosIndividual: Array<{ escala: iEscala, grafico: string }> = []
    const graficosHierarquia: Array<{ escala: iEscala, grafico: string }> = []
    return Model.graficosEscalasAlternativas?.map((e, i) => {
      return (
        <>
          <PDFRadarChart
            setGrafico={(g) => { if (e.hierarquia.escala) { graficosHierarquia.push({ escala: e.hierarquia.escala, grafico: g }) }; if (Model.graficosEscalasAlternativas && i === (Model.graficosEscalasAlternativas?.length - 1)) { setGraficosEscalasAlternativasHierarquia(graficosHierarquia) } }}
            notaMinimaEscala={0}
            notaMaximaEscala={e.hierarquia.escala?.respostas.some(e => e.peso > 6) ? 10 : e.hierarquia.escala?.respostas.some(e => e.peso > 5) ? 6 : 5}
            stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
            grafico={e.hierarquia}
          />
          <PDFRadarChart
            setGrafico={(g) => { if (e.individual.escala) { graficosIndividual.push({ escala: e.individual.escala, grafico: g }) }; if (Model.graficosEscalasAlternativas && i === (Model.graficosEscalasAlternativas?.length - 1)) { setGraficosEscalasAlternativasIndividual(graficosIndividual) } }}
            notaMinimaEscala={0}
            notaMaximaEscala={e.individual.escala?.respostas.some(e => e.peso > 6) ? 10 : e.individual.escala?.respostas.some(e => e.peso > 5) ? 6 : 5}
            stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
            grafico={e.individual}
          />
        </>
      )
    })
  }

  function GetGraficosPDIs(): any {
    const graficos: string[] = []
    return Model.graficosPDI?.map((e, i) => {
      return (
        <Flex h={'750px'}>
          <PDIChart
            grafico={e}
            numeroRespostas={Model.totalRespostas}
            id={`pdi-chart-comps-${i}-pdf`}
            getBlob={(g) => { graficos.push(g); if (Model.graficosPDI && i === (Model.graficosPDI?.length - 1)) { setGraficosPDI(graficos) } }}
          />
        </Flex>
      )
    })
  }

  useEffect(() => {
    getResultado()
    CheckComentariosVisiveis()
  }, [])

  useEffect(() => {
    getRespostasMelhoradas()
  }, [Comparativo])

  useEffect(() => {
    if (Model.graficoInvertido) {
      setTabGrafico(2)
    } else if (Model.graficoHierarquia?.dados.length === 2) {
      setTabGrafico(0)
    }

    if (Model.recomendacoes.length !== Model.priorizadas.length) {
      return
    }

    if (!PropInvalida(Model.comparativoId)) {
      FiltrarComaparativo(Model.comparativoId ?? '')
    }

    if (!PropInvalida(Model.escala.respostas[0])) {
      setNotaMinima(0)
      setNotaMinimaFinal(0)
    }
  }, [Model])

  useEffect(() => {
    setIsLoadingChart(false)
  }, [NotaMaxima, NotaMinima, RelatorioComparativo])

  useEffect(() => {
    setIsLoadingIndividual(true)
    setTimeout(() => {
      setIsLoadingIndividual(false)
    }, 700)
  }, [TabGrafico])

  return (
    <Body isLoading={IsLoading}>
      <ModalDataDevolutiva
        check={Model.entregaDevolutiva}
        data={Model.dataDevolutiva}
        isOpen={ModalDataOpen}
        onConfirm={(Data, Checkbox) => onAlterarDevolutiva(Data, Checkbox)}
        onRequestClose={() => setModalDataOpen(false)}
      />

      <ModalPDI
        recomendacoes={Model.recomendacoes.length}
        isOpen={ModalPDIsOpen}
        onCreate={CriarBasePDI}
        onRequestClose={() => setModalPDIsOpen(false)}
      />

      <Flex opacity={'0'} flexDir='column' position={'absolute'} zIndex={-1} justifyContent={'center'} width={'100%'} maxWidth='1168px'>
        <Flex width={'100%'} flexDir='column'>
          {(Comparativo?.graficoComparativo && GraficoComparativoAutoavaliacao) && (
            <ComparativoChart getBlob={(a) => setGrafico3(a)} fator={Model.escala.fator} grafico={{
              competencias: Comparativo?.graficoComparativo.competencias,
              dados: Comparativo?.graficoComparativo.dados
            }} id='gPDF-1' />
          )}

          {(Comparativo?.graficoComparativoAutoavaliacao && !GraficoComparativoAutoavaliacao) && (
            <ComparativoChart getBlob={(a) => setGrafico3(a)} fator={Model.escala.fator} grafico={{
              competencias: Comparativo?.graficoComparativoAutoavaliacao.competencias,
              dados: Comparativo?.graficoComparativoAutoavaliacao.dados
            }} id='gPDF-0' />
          )}

          {Model.graficosPercentual?.map((e, i) => {
            return (
              <ChartPercentual
                key={i}
                id={`grf-pdf-${i}`}
                grafico={e}
                escala={Model.escala}
                getBlob={(e) => setGraficos(old => [...old, e])}
              />
            )
          })}

          {GetGraficosEscalasAlternativas()}

          {GetGraficosPDIs()}
        </Flex>

        <Flex width={'60%'} flexDir='column'>
          {!IsLoadingChart && Model.graficoIndividual && <PDFRadarChart
            setGrafico={(e) => setGrafico1(e)}
            notaMinimaEscala={NotaMinima}
            notaMaximaEscala={NotaMaxima}
            stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
            grafico={Model.graficoIndividual}
          />}

          {!IsLoadingChart && Model.graficoHierarquia && <PDFRadarChart
            setGrafico={(e) => setGrafico2(e)}
            notaMinimaEscala={NotaMinima}
            notaMaximaEscala={NotaMaxima}
            stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
            grafico={Model.graficoHierarquia}
          />}

          {!IsLoadingChart && Model.graficoInvertido &&
            <PDFRadarChart
              setGrafico={(e) => setGrafico4(e)}
              notaMinimaEscala={NotaMinima}
              notaMaximaEscala={NotaMaxima}
              stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
              grafico={Model.graficoInvertido}
            />
          }
        </Flex>

      </Flex>

      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: pathname.includes('Desempenho') ? '/Desempenho/AnaliseDesempenho/Gestao' : '/Jornada/Percepcao/Gestao'
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: -1
          }, {
            nome: Model?.avaliado?.nome ?? ''
          }
        ]} />
      </Flex>

      <Flex marginBottom={'1rem'} alignItems={'center'} justifyContent={'space-between'} flexFlow='nowrap'>
        <Flex flexDir={'column'}>
          <Flex>
            <Avatar
              marginRight={'.625rem'}
              w={'75px'}
              h={'75px'}
              name={Model?.avaliado.nome}
              src={Model?.avaliado.avatar}
            />
            <Flex flexDir={'column'} justifyContent={'center'}>
              <h4>Avaliado:</h4>
              <Text fontSize={'1.25rem'} fontWeight={'700'} lineHeight={'1.25rem'}>{Model?.avaliado.nome}</Text>
              <h4>Departamento: <strong>{Model?.avaliado?.nomeDepartamento}</strong></h4>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap={'1rem'}>
          {(Model.avaliado.perfilPI) && (
            <DashBox style={{ padding: '0 .5rem', position: 'relative', width: '15rem', height: '6rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
              <Flex flexDirection={'column'} gap={'.5rem'}>
                <Flex flexDir='column' width='100%' alignItems={'center'}>
                  {
                    (Model.avaliado.perfilPI?.nomeSelo) && (
                      <Text fontWeight={'500'} fontSize={'12px'}>Perfil comportamental: <Text as={'span'} fontWeight={900}>{Model.avaliado.perfilPI?.nomeSelo}</Text></Text>
                    )
                  }
                  {
                    (Model.avaliado.perfilPI?.urlSelo) && (
                      <Image mt={'.3rem'} width={'2rem'} src={Model.avaliado.perfilPI.urlSelo} />
                    )
                  }
                </Flex>
                <Menu>
                  <MenuButtonCmp small VarColor='Green2'>
                    Baixar Relatorios
                  </MenuButtonCmp>
                  <MenuList bgColor='var(--Azul)'>
                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlResultado)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlResultado}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Perfil comportamental
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlQuadro)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlQuadro}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento pessoal
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlPlacard)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlPlacard}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Placard
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlQuadroGestor)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlQuadroGestor}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento gestores
                          </Box>
                        </MenuItem>
                      )
                    }

                  </MenuList>
                </Menu>
              </Flex>
            </DashBox>
          )}
          <InfoBox style={{ flexDirection: 'column', gap: '.125rem 0' }}>
            <span>Média geral</span>
            <h4>{Model.apenasAutoAvaliacao ? handleMedia().toFixed(2) : Model?.mediaGeral.toFixed(2)}</h4>
          </InfoBox>

          <InfoBox>
            <Flex pl={'1rem'}>
              <Image w={'2rem'} h={'2rem'} src={IconeAvaliadores} />
            </Flex>
            <Flex pr={'.75rem'} flexDir={'column'} gap={'.325rem'}>
              <h4>{Model?.totalRespostas}/{Model.totalAvaliadores}</h4>
              <span>Respostas/Total</span>
            </Flex>
          </InfoBox>
        </Flex>
      </Flex>
      {
        (!ModoEdicaoPrioridades && Model.recomendacoes.length > 0) && (
          <>
            <Flex justifyContent={'start'}>
              <h3>Potencial para melhorias</h3>
            </Flex>

            <WhiteContainer margin='.75rem 0'>
              <Grid templateColumns='repeat(2, 1fr)' gap={'1rem'}>
                {
                  Model?.recomendacoes.map((e, i) => {
                    return (
                      <Recomendacao pos={i + 1} recomendacao={e} key={i} />
                    )
                  })
                }
              </Grid>
            </WhiteContainer>
          </>
        )
      }

      {!ModoEdicaoPrioridades &&
        <>
          <Flex gap={'.5rem'}>
          {!IsLoadingChart && Model.tabela.length > 0 && <PDFDownloadLink
            document={
              <RelatorioPDF
                comparativo={Model.filtro ? Model.filtro.filter(e => e.id === AtualComparativo)[0] ? Model.filtro.filter(e => e.id === AtualComparativo)[0].nome : '' : ''}
                comparativoRespostas={ComparativoRespostas ?? { melhor: 0, total: 0 }}
                temColuna={TemColuna}
                Grafico1={Grafico1}
                Grafico2={Grafico2}
                Grafico3={Grafico3}
                Grafico4={Grafico4}
                GraficosPDI={GraficosPDI}
                GraficosPDICompetencias={Model.graficosPDI?.filter(e => e.competencias.length > 0).map(e => { return e.competencias[0] })}
                graficosEscalasAlternativasHierarquia={GraficosEscalasAlternativasHierarquia}
                graficosEscalasAlternativasIndividual={GraficosEscalasAlternativasIndividual}
                graficosPercentual={Graficos}
                comparativoOn={RelatorioComparativo}
                model={Model}
                tipoPesquisa={pathname.includes('AnaliseDesempenho') ? 'Pesquisa de análise e desempenho' : 'Pesquisa de percepção'}
              />
            }
            fileName={`Relatorio_Pesquisa_Percepcao_${Model.avaliado.nome.replace(/\s/g, '_')}.pdf`}
          >
            {({ loading }) =>
              (loading || ((PropInvalida(Comparativo) && RelatorioComparativo)))
                ? (
                  <ButtonCmp VarColor='Green2' leftIcon={<FaDownload/>} isLoading>Baixar relatório</ButtonCmp>
                  )
                : <ButtonCmp VarColor='Green2' leftIcon={<FaDownload/>}>Baixar relatório</ButtonCmp>

            }
          </PDFDownloadLink>}
          {(Model.adicionarPDI && (pathname.includes('AnaliseDesempenho') || Model.is90Graus) && PropInvalida(Model.pdiAvaliacao)) && (
            <ButtonCmp
              fontWeight={'500'}
              onClick={() => {
                !PropInvalida(Model.pdiNormal) ? setModalPDIsOpen(true) : CriarBasePDI('1')
              }}
              leftIcon={<FaClipboard />} VarColor='Green2'
            >Adicionar PDI</ButtonCmp>
          )}

          {((pathname.includes('AnaliseDesempenho') || Model.is90Graus) && !PropInvalida(Model.pdiAvaliacao)) && (
            <ButtonCmp
              fontWeight={'500'}
              onClick={() => nav(`/Desempenho/PDI/Editar/${Model.pdiAvaliacao ?? ''}`)}
              leftIcon={<FaClipboard />} VarColor='Green2'
            >Editar PDI</ButtonCmp>
          )}

          {Model.isResponsavel && (
            <ButtonCmp
              VarColor='Gray3'
              leftIcon={<AiFillTool />}
              onClick={() => setModoEdicaoPrioridades(true)}
            >Modificar competências para melhoria</ButtonCmp>
          )}

          {(Model.liberarResultado === 0 && (parseJwt().adm === 'True' || parseJwt().cid === Model.avaliado.gestorId)) && (
              <ButtonCmp
                borderRadius={'.5rem'}
                VarColor='Rosa'
                leftIcon={<FaEyeSlash />}
                onClick={() => AlterarVisibiliade(false)}
              >Ocultar resultado</ButtonCmp>
          )}

          {(Model.liberarResultado === 1 && (parseJwt().adm === 'True' || parseJwt().cid === Model.avaliado.gestorId)) && (
              <ButtonCmp
                leftIcon={<FaEye />}
                borderRadius={'.5rem'}
                VarColor='Rosa'
                onClick={() => AlterarVisibiliade(true)}
              >Liberar resultado</ButtonCmp>
          )}

        {Model.monitorarDevolutiva && parseJwt().adm === 'True' &&
          (
            <ButtonCmp VarColor={Model.entregaDevolutiva ? 'Green1' : 'Red'} leftIcon={!Model.entregaDevolutiva ? <FaFileUpload /> : <FaCheck />} onClick={() => setModalDataOpen(true)}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <span>Devolutiva</span>
                <small style={{ fontSize: '10px', fontWeight: 400 }}>{Model.entregaDevolutiva ? Model.dataDevolutiva : ''}</small>
              </Box>
            </ButtonCmp>
          )
        }
      </Flex>
      <Flex gap={'1rem'} my={'.75rem'}>
        <CheckboxCmp isDisabled={AtualComparativo === ''} isChecked={RelatorioComparativo} OnChange={(e) => { setIsLoadingChart(true); setRelatorioComparativo(e) }}>Incluir comparativo no relatório</CheckboxCmp>
      </Flex>

      <Tabs variant='unstyled'>
        <TabList >
          <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Gráfico {Model.graficosEscalasAlternativas && Model.graficosEscalasAlternativas.length > 0 ? ' da escala padrão' : ''}</Tab>
          {Model.graficosEscalasAlternativas && Model.graficosEscalasAlternativas.length > 0 && <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Demais gráficos</Tab>}
          <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Tabelas</Tab>
          {Model.graficosPercentual && <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Percentual de respostas</Tab>}
          {Model.tabelaPDI && Model.tabelaPDI.length > 0 && Model.graficosPDI && <Tab backgroundColor={'white'} color={'var(--c6)'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>PDI</Tab>}
          <Tab backgroundColor={'white'} color={'var(--c6)'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Comparativo</Tab>
          {(Model && Model?.discursivas.length > 0) && ComentariosVisiveis && <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Comentários</Tab>}
          {!Model.apenasAutoAvaliacao && <Tab
            backgroundColor={'white'}
            color={'var(--c6)'}
            borderRadius={'0 .25rem 0 0'}
            _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}
          >
          {Model.acoes
            ? 'Recomendações'
            : <Flex alignItems={'center'} gap={'1rem'}>
              Carregando recomendações
              <Spinner size={'sm'}/>
            </Flex>
          }
          </Tab>}
        </TabList>
        <TabPanels background={'white'} boxShadow={'var(--SombraBackground)'} borderBottomLeftRadius='.25rem' borderBottomRightRadius='.25rem'>
          <TabPanel>
            <Flex flexDir={'column'} width={'100%'}>
              {TabGrafico === 0 && Model.graficoHierarquia && (
                <Flex flexDir={'column'} width='100%'>
                  <Flex justifyContent={'space-between'} alignItems={'center'} gap={'.5rem'}>
                    <Flex justifyContent={'space-between'} gap={'.35rem'} flex={'1'}>
                      <Flex gap={'.75rem'} flex={'1'}>
                        <Titulo>Gráfico de hierarquia</Titulo>
                        <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                            <ButtonCmp
                              VarColor='Rosa'
                              small
                              onClick={() => DownloadImage(Grafico2 ?? '')}
                            ><MdDownload size={14} /></ButtonCmp>
                        </ElementoTooltipCmp>
                      </Flex>
                      {(Model.graficoHierarquia) && (
                        <SwitchCmp value={TabGrafico === 1} OnChange={(e) => { setTabGrafico(1) }} legendaDireita={'Gráfico individual'} legendaEsquerda={'Gráfico de hierarquia'}/>
                      )}
                    </Flex>
                  </Flex>
                  <LoadingContainer linhas={12} loading={IsLoadingIndividual} my={'1rem'}>
                    <Flex justifyContent={'center'} width='100%' position={'relative'}>
                      <Flex width={'50%'}>
                        <RadarChartJs
                          competencias={Model?.graficoHierarquia.competencias ?? []}
                          dados={Model?.graficoHierarquia.dados ?? []}
                          notaMinimaEscala={NotaMinimaFinal}
                          notaMaximaEscala={NotaMaximaFinal}
                          stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                        />
                      </Flex>
                    </Flex>
                    <Box display={'flex'} gap={'.5rem'} justifyContent={'center'} mt={'-1rem'} mb={!ModoEdicao ? '2.5rem' : '2rem'} bg={'transparent'}>
                      <ButtonCmp
                        VarColor='Roxo'
                        onClick={() => setModoEdicao(!ModoEdicao)}
                      >Modo de edição do eixo</ButtonCmp>
                    </Box>
                  </LoadingContainer>
                  {(ModoEdicao) &&
                    <Flex fontSize={'18px'} fontWeight={'600'} flexDirection={'column'} mb={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1.5rem'}>
                      <Box display={'flex'} fontSize={'.875rem'} justifyContent={'space-between'} alignItems={'center'}><Text as={'span'} minW={'6.5rem'}>Limite mínimo:</Text> <InputCmp max={GetMaiorMenorNota('<')} step={'0.5'} type={'number'} value={NotaMinima} OnChange={(e) => ChangeInputNota(e, 'min')} w={'5rem'} /></Box>
                      <Box display={'flex'} fontSize={'.875rem'} justifyContent={'space-between'} alignItems={'center'}><Text as={'span'} minW={'6.5rem'}>Limite máximo:</Text> <InputCmp min={GetMaiorMenorNota('>')} step={'0.5'} type={'number'} value={NotaMaxima} OnChange={(e) => ChangeInputNota(e, 'max')} w={'5rem'} /></Box>
                      <ButtonCmp VarColor='Green2' onClick={() => {
                        SetIsLoading(true)
                        setIsLoadingChart(true)
                        setNotaMinimaFinal(NotaMinima)
                        setNotaMaximaFinal(NotaMaxima)
                        setTimeout(() => {
                          setIsLoadingChart(false)
                        }, 500)
                        setTimeout(() => {
                          SetIsLoading(false)
                        }, 3000)
                      }}>Salvar</ButtonCmp>
                    </Flex>
                  }
                </Flex>)
              }

              {TabGrafico === 1 && Model.graficoIndividual && (
                <Flex flexDir={'column'} width='100%'>
                  <Flex justifyContent={'space-between'}>
                    <Flex w={'full'} alignItems={'center'} gap={'.5rem'}>
                      <Flex justifyContent={'space-between'} gap={'.35rem'} flex={'1'} w={'100%'}>
                        <Flex gap={'.75rem'} flex={'1'}>
                          <Titulo>Gráfico individual</Titulo>
                          <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                            <ButtonCmp
                              VarColor='Rosa'
                              small
                              onClick={() => DownloadImage(Grafico1 ?? '')}
                            ><MdDownload size={14} /></ButtonCmp>
                          </ElementoTooltipCmp>
                        </Flex>
                        {(Model.graficoIndividual) && (
                          <SwitchCmp value={TabGrafico === 1} OnChange={(e) => { setTabGrafico(0) }} legendaDireita={'Gráfico individual'} legendaEsquerda={'Gráfico de hierarquia'}/>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  <LoadingContainer linhas={12} loading={IsLoadingIndividual} my={'1rem'}>
                    <Flex justifyContent={'center'} width='100%' position={'relative'}>
                      <Flex width={'50%'}>
                        <RadarChartJs
                          competencias={Model.graficoIndividual.competencias ?? []}
                          dados={Model.graficoIndividual.dados ?? []}
                          notaMinimaEscala={NotaMinima}
                          notaMaximaEscala={NotaMaxima}
                          stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala.respostas[1]?.peso ?? 0)) ?? 0}
                        />
                      </Flex>
                    </Flex>
                    <Box display={'flex'} gap={'.5rem'} justifyContent={'center'} mt={'-3rem'} mb={!ModoEdicao ? '2.5rem' : '2rem'} bg={'transparent'}>
                      <ButtonCmp VarColor='Roxo' onClick={() => setModoEdicao(!ModoEdicao)}>Modo de edição do eixo</ButtonCmp>
                    </Box>
                  </LoadingContainer>
                  {(ModoEdicao) &&
                    <Flex fontSize={'18px'} fontWeight={'600'} flexDirection={'column'} mb={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1.5rem'}>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite mínimo: <Input max={GetMaiorMenorNota('<')} step={'0.5'} type={'number'} value={NotaMinima} onChange={(e) => ChangeInputNota(e.target.value, 'min')} w={'5rem'} /></Box>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite máximo: <Input min={GetMaiorMenorNota('>')} step={'0.5'} type={'number'} value={NotaMaxima} onChange={(e) => ChangeInputNota(e.target.value, 'max')} w={'5rem'} /></Box>
                    </Flex>}
                </Flex>)
              }

                {TabGrafico === 2 && Model.graficoInvertido && (
                  <Flex flexDir={'column'} width='100%'>
                    <Titulo>Gráfico de hierarquia</Titulo>
                    <Flex justifyContent={'flex-end'}>
                      <ButtonCmp
                        VarColor='Roxo'
                        onClick={() => setModoEdicao(!ModoEdicao)}
                      >Modo de edição do eixo</ButtonCmp>
                    </Flex>
                    <Flex justifyContent={'center'} width='100%' position={'relative'}>
                      <Flex width={'50%'}>
                        <RadarChartJs
                          competencias={Model?.graficoInvertido.competencias ?? []}
                          dados={Model?.graficoInvertido.dados ?? []}
                          notaMinimaEscala={NotaMinima}
                          notaMaximaEscala={NotaMaxima}
                          stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                        />
                      </Flex>
                    </Flex>
                    {(ModoEdicao) &&
                      <Flex fontSize={'18px'} fontWeight={'600'} flexDirection={'column'} mb={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1.5rem'}>
                        <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite mínimo: <Input max={GetMaiorMenorNota('<')} step={'0.5'} type={'number'} value={NotaMinima} onChange={(e) => ChangeInputNota(e.target.value, 'min')} w={'5rem'} /></Box>
                        <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite máximo: <Input min={GetMaiorMenorNota('>')} step={'0.5'} type={'number'} value={NotaMaxima} onChange={(e) => ChangeInputNota(e.target.value, 'max')} w={'5rem'} /></Box>
                      </Flex>
                    }
                  </Flex>)
                }
              </Flex>
              <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                {Model.escala?.respostas.map((e, i) => {
                  return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                })}
              </Flex>
            </TabPanel>
            {Model.graficosEscalasAlternativas && Model.graficosEscalasAlternativas.length > 0 && <TabPanel>
              <Flex flexDir={'column'} gap={'4rem'}>
                {Model.graficosEscalasAlternativas.map(e => {
                  return (
                    <Flex flexDir={'column'} gap='1rem'>
                      <div>
                        <Flex flexDir={'column'} width={'100%'}>
                          <Flex flexDir={'column'} width='100%'>
                            <Flex justifyContent={'space-between'}>
                              <Flex w={'full'} alignItems={'center'} gap={'.5rem'}>
                                <Flex justifyContent={'space-between'} gap={'.35rem'} flex={'1'} w={'100%'}>
                                  <Flex gap={'.75rem'} flex={'1'}>
                                    <Titulo>Gráfico individual da {e.individual.escala?.nome}</Titulo>
                                    <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                                      <ButtonCmp
                                        VarColor='Rosa'
                                        small
                                        onClick={() => DownloadImage(GraficosEscalasAlternativasIndividual.find(a => e.individual.escala && (a.escala.id === e.individual.escala.id))?.grafico ?? '')}
                                      ><MdDownload size={14} /></ButtonCmp>
                                    </ElementoTooltipCmp>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                            <LoadingContainer linhas={12} loading={IsLoadingIndividual} my={'1rem'}>
                              <Flex justifyContent={'center'} width='100%' position={'relative'}>
                                <Flex width={'50%'}>
                                  <RadarChartJs
                                    competencias={e.individual.competencias ?? []}
                                    dados={e.individual.dados ?? []}
                                    notaMinimaEscala={0}
                                    notaMaximaEscala={e.individual.escala?.respostas.some(e => e.peso > 6) ? 10 : e.individual.escala?.respostas.some(e => e.peso > 5) ? 6 : 5}
                                    stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala.respostas[1]?.peso ?? 0)) ?? 0}
                                  />
                                </Flex>
                              </Flex>
                          </LoadingContainer>
                          </Flex>
                        </Flex>
                        <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                          {e.individual.escala?.respostas.map((es, i) => {
                            return (<span key={i}>{es.resposta}{es.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({es.peso})</strong>};</span>)
                          })}
                        </Flex>
                      </div>
                      <div>
                        <Flex flexDir={'column'} width={'100%'}>
                          <Flex flexDir={'column'} width='100%'>
                            <Flex justifyContent={'space-between'}>
                              <Flex w={'full'} alignItems={'center'} gap={'.5rem'}>
                                <Flex justifyContent={'space-between'} gap={'.35rem'} flex={'1'} w={'100%'}>
                                  <Flex gap={'.75rem'} flex={'1'}>
                                    <Titulo>Gráfico por hierarquia da {e.hierarquia.escala?.nome}</Titulo>
                                    <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                                      <ButtonCmp
                                        VarColor='Rosa'
                                        small
                                        onClick={() => DownloadImage(GraficosEscalasAlternativasHierarquia.find(a => e.hierarquia.escala && (a.escala.id === e.hierarquia.escala.id))?.grafico ?? '')}
                                      ><MdDownload size={14} /></ButtonCmp>
                                    </ElementoTooltipCmp>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                            <LoadingContainer linhas={12} loading={IsLoadingIndividual} my={'1rem'}>
                              <Flex justifyContent={'center'} width='100%' position={'relative'}>
                                <Flex width={'50%'}>
                                  <RadarChartJs
                                    competencias={e.hierarquia.competencias ?? []}
                                    dados={e.hierarquia.dados ?? []}
                                    notaMinimaEscala={0}
                                    notaMaximaEscala={e.hierarquia.escala?.respostas.some(e => e.peso > 6) ? 10 : e.hierarquia.escala?.respostas.some(e => e.peso > 5) ? 6 : 5}
                                    stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala.respostas[1]?.peso ?? 0)) ?? 0}
                                  />
                                </Flex>
                              </Flex>
                          </LoadingContainer>
                          </Flex>
                        </Flex>
                        <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                          {e.hierarquia.escala?.respostas.map((es, i) => {
                            return (<span key={i}>{es.resposta}{es.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({es.peso})</strong>};</span>)
                          })}
                        </Flex>
                      </div>
                    </Flex>
                  )
                })}
              </Flex>
              </TabPanel>}

            <TabPanel>
              <Flex flexDir={'column'} gap={'.5rem'}>
                <Titulo>Tabela dos resultados por hierarquia</Titulo>
                <Tabela
                  cores={Model.cores}
                  discursivas={Model.discursivas}
                  linhas={Model.tabela}
                  mediaGeral={Model.mediaGeral}
                  recomendacoes={Model.recomendacoes}
                  temColuna={TemColuna}
                  apenasAutoAvaliacao={Model.apenasAutoAvaliacao}
                />
                <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                  {Model.escala?.respostas.map((e, i) => {
                    return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                  })}
                </Flex>
                <Flex justifyContent={'center'} gap={'1rem'} w={'100%'}>
                  <Flex alignItems={'center'} gap={'.25rem'} fontSize={'.85rem'}>
                    <Box w={'.65rem'} h={'.65rem'} bgColor={'var(--Orange)'}></Box>
                    Notas mais baixas
                  </Flex>
                  <Flex alignItems={'center'} gap={'.25rem'} fontSize={'.85rem'}>
                    <Box w={'.65rem'} h={'.65rem'} bgColor={'#00aa00'}></Box>
                    Notas mais altas
                  </Flex>
                </Flex>
              </Flex>
              {Model.tabelasEscalasAlternativas && Model.tabelasEscalasAlternativas.length > 0 && <Flex flexDir={'column'} gap={'2rem'} mt={'1rem'}>
                {Model.tabelasEscalasAlternativas.map(e => {
                  return (<Flex flexDir={'column'} gap='.5rem'>
                    <Titulo>Tabela da {e.escala?.nome}</Titulo>
                    <Tabela
                      cores={[]}
                      discursivas={Model.discursivas}
                      linhas={e.tabela}
                      mediaGeral={CalcularMediaGeral(e.tabela)}
                      recomendacoes={[]}
                      temColuna={TemColuna}
                      apenasAutoAvaliacao={false}
                    />
                    <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                      {e.escala?.respostas.map((e, i) => {
                        return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                      })}
                    </Flex>
                  </Flex>)
                })}
              </Flex>}
            </TabPanel>
            {Model.tabelaPDI && Model.tabelaPDI.length > 0 && Model.graficosPDI && <TabPanel>
              {Model.boxPDI && Model.boxPDI.filter(e => e.status !== 2).length > 0 && <Grid gridTemplateColumns={'repeat(2, 1fr)'} mb={'1.25rem'} gap={'1rem'}>
                  {Model.boxPDI.filter(e => e.status === 0).length > 0 && <BoxPDI model={Model.boxPDI ?? []} status={0}/>}
                  {Model.boxPDI.filter(e => e.status === 1).length > 0 && <BoxPDI model={Model.boxPDI ?? []} status={1}/>}
                </Grid>}
              <Flex flexDir={'column'} gap={'.5rem'}>
                <Flex alignItems={'center'} gap={'.5rem'}>
                  <RiBarChartHorizontalFill color='var(--Azul)' size={22}/>
                  <Titulo>Tabela das competências trabalhadas</Titulo>
                </Flex>
                <TabelaPDI
                  discursivas={Model.discursivas}
                  linhas={Model.tabelaPDI}
                  temColuna={TemColuna}
                />
              </Flex>
              <Flex width={'100%'} margin={'2rem auto 0 auto'} flexDir={'column'} gap={'.5rem'} overflow='hidden'>
                <Flex alignItems={'center'} gap='.5rem' mb={'1rem'}>
                  <FaRegChartBar color='var(--Azul)' size={22}/>
                  <Titulo>Gráficos de respostas por competência trabalhada</Titulo>
                </Flex>
                <Grid gridTemplateColumns={'repeat(2, 1fr)'} flexDir={'column'} gap={'1.5rem'} alignItems={'center'}>
                  {Model.graficosPDI.map((e, i) => {
                    return (
                      <Flex flexDir={'column'} gap={'1.75rem'} p={'1rem'} border={'1px solid var(--c6)'} borderRadius={'.325rem'} height={'26rem'}>
                        <Flex fontSize={'14px'} fontWeight={'700'} justifyContent={'center'}>{e.competencias.length > 0 ? e.competencias[0] : ''}</Flex>
                        <PDIChart
                          grafico={e}
                          numeroRespostas={Model.totalRespostas}
                          id={`pdi-chart-comps-${i}`}
                        />
                      </Flex>
                    )
                  })}
                </Grid>
              </Flex>
              <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                {Model.escalaPDI?.respostas.map((e, i) => {
                  return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                })}
              </Flex>
            </TabPanel>}
            {Model.graficosPercentual &&
              <TabPanel>
                <Flex flexDir={'column'} width='100%'>
                  <Titulo>Gráfico de percentual</Titulo>
                  <Flex flexWrap={'wrap'} justifyContent={'center'} gap={'1rem'} my='1rem'>
                    {Model.escala.respostas.filter(e => e.peso !== -1).map((e, i) => {
                      return (
                        <Flex key={e.id} gap={'1rem'}>
                          <Box
                            w={'1.625rem'}
                            h={'1.625rem'}
                            bgColor={colors[i]}
                          /> {e.resposta}</Flex>
                      )
                    })}
                  </Flex>
                  <Flex flexDir={'column'} gap='2rem'>
                    {Model.graficosPercentual.map((e, i) => {
                      return (
                        <Flex key={i} justifyContent={'center'} width='100%' position={'relative'}>
                          <Flex width={'80%'}>
                            <ChartPercentual
                              id={`grf-${i}`}
                              grafico={e}
                              escala={Model.escala} />
                          </Flex>
                        </Flex>
                      )
                    })}
                  </Flex>

                  <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                    {Model.escala?.respostas.map((e, i) => {
                      return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                    })}
                  </Flex>
                </Flex>
              </TabPanel>
            }

          <TabPanel>
              <Flex width={'100%'} flexDir='column' gap={'.5rem'}>
                {
                  (Comparativo?.graficoComparativo) &&
                  <Flex justifyContent={'space-between'}>
                    <Titulo>{GraficoComparativoAutoavaliacao ? 'Gráfico de comparativo das médias sem autoavaliação' : 'Gráfico comparativo - autoavaliacão'}</Titulo>
                      <SwitchCmp value={GraficoComparativoAutoavaliacao} OnChange={(e) => { OnChangeGraficoComparativo() }} legendaDireita={'Sem autoavaliação'} legendaEsquerda={'Apenas autoavaliacão'}/>
                  </Flex>
                }
                  <Flex flexDir={'column'} width='100%'>
                    <FormControl>
                      <SelectCmp title='Pesquisa comparada' value={AtualComparativo} OnChange={FiltrarComaparativo}>
                        <option value={''}>Mais recente</option>
                        {
                          Model?.filtro?.filter(e => e.id !== avaliacaoId).map((e) => {
                            return (
                              <option key={e.id} value={e.id}>{e.nome}</option>
                            )
                          })
                        }
                      </SelectCmp>
                    </FormControl>
                  </Flex>
                <LoadingContainer linhas={12} loading={IsLoadingComparativo}>
                {!Comparativo?.graficoComparativo && (!Model?.filtro.filter(e => e.id !== avaliacaoId) || Model?.filtro.filter(e => e.id !== avaliacaoId)?.length === 0) && <Flex alignItems={'center'} justifyContent={'center'} fontWeight='700' py={'7rem'}>
                  Não há resultados anteriores para apresentar o comparativo
                </Flex>}
                {
                  (Comparativo?.graficoComparativo && GraficoComparativoAutoavaliacao) && (
                    <Flex justifyContent={'center'} width='100%'>
                      <Flex width={'100%'}>
                        <ComparativoChart fator={Model.escala.fator} grafico={{
                          competencias: Comparativo?.graficoComparativo.competencias,
                          dados: Comparativo?.graficoComparativo.dados
                        }} id='g-1' />
                      </Flex>
                    </Flex>
                  )
                }
                {
                  (Comparativo?.graficoComparativoAutoavaliacao && !GraficoComparativoAutoavaliacao) && (
                    <Flex justifyContent={'center'} width='100%'>
                      <Flex width={'100%'}>
                        <ComparativoChart fator={Model.escala.fator} grafico={{
                          competencias: Comparativo?.graficoComparativoAutoavaliacao.competencias,
                          dados: Comparativo?.graficoComparativoAutoavaliacao.dados
                        }} id='g-0' />
                      </Flex>
                    </Flex>
                  )
                }
                </LoadingContainer>
              </Flex>
              <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                {Model.escala?.respostas.map(e => {
                  return (<span key={e.id}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                })}
              </Flex>
          </TabPanel>

            {(Model && Model.discursivas.length > 0 && ComentariosVisiveis) && (
              <TabPanel>
                {!Model.discursivas.every(e => PropInvalida(e.tipoAvaliador)) && <Flex gap={'1rem'} marginBottom='1rem' width='50%'>
                  <FormControl>
                    <SelectCmp title='Questões' OnChange={setFiltroCompetencia}>
                      <option value={''}>Todos</option>
                      {
                        HSelect(Model.discursivas, e => e.titulo, true).map((e, i) => {
                          return (
                            <option value={e} key={i}>{e}</option>
                          )
                        })
                      }
                    </SelectCmp>
                  </FormControl>

                  <FormControl>
                    <SelectCmp title='Tipo do avaliador' OnChange={setFiltroTipo}>
                      <option value={''}>Todos</option>
                      {TemColuna(0) && <option value={'0'}>Autoavaliação</option>}
                      {TemColuna(1) && <option value={'1'}>Gestor</option>}
                      {TemColuna(2) && <option value={'2'}>Par</option>}
                      {TemColuna(3) && <option value={'3'}>Liderado</option>}
                      {TemColuna(6) && <option value={'6'}>Alta liderança</option>}
                      {TemColuna(4) && <option value={'4'}>Outro</option>}
                    </SelectCmp>
                  </FormControl>
                </Flex>}
                <h3 style={{ fontSize: '1rem' }}>Comentários: {Model.discursivas.filter((e) => {
                  if (FiltroCompetencia === '' && FiltroTipo === '') {
                    return e
                  } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                    if (e.titulo.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                      return e
                    }
                    return null
                  } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                    return e
                  } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                    return e
                  }
                  return null
                }).length}</h3>
                <Flex marginTop={'.5rem'} flexDir={'column'} gap='.5rem'>
                  {
                    Model.discursivas.filter((e) => {
                      if (FiltroCompetencia === '' && FiltroTipo === '') {
                        return e
                      } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                        if (e.titulo.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                          return e
                        }
                        return null
                      } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                        return e
                      } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                        return e
                      }
                      return null
                    }).map((e, i) => {
                      return (
                        <ComentariosContainer key={i}>
                          <span>{e.titulo}</span>
                          <strong>{e.texto}</strong>
                        </ComentariosContainer>
                      )
                    })
                  }
                </Flex>
              </TabPanel>)
            }
            {!Model.apenasAutoAvaliacao && <TabPanel px={'2rem'} >
              <TabRecomendacao model={Model} onGerarRecomendacoes={ObterPlanoAcao}/>
            </TabPanel>}
          </TabPanels>
        </Tabs>
        </>
      }

      {ModoEdicaoPrioridades &&
        <WhiteContainer>
          <Flex flexDir={'column'} gap={'1.25rem'}>
            <h3 style={{ marginBottom: '.5rem' }}>Potenciais para melhorias</h3>
            <Flex flexDir={'column'} gap='.5rem'>
              <Titulo>Tabela dos resultados por hierarquia</Titulo>
              <Tabela
                cores={Model.cores}
                discursivas={Model.discursivas}
                linhas={Model.tabela}
                recomendacoes={Model.recomendacoes}
                temColuna={TemColuna}
                mediaGeral={Model.mediaGeral}
                priorizadas={Model.priorizadas}
                onPriorizar={(e) => setModel({ ...Model, priorizadas: e })}
                apenasAutoAvaliacao={Model.apenasAutoAvaliacao}
              />
              <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                {Model.escala?.respostas.map((e, i) => {
                  return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                })}
              </Flex>
            </Flex>

            {Model?.tabelasEscalasAlternativas?.map(e => {
              return (<Flex flexDir={'column'} gap='.5rem'>
              <Titulo>Tabela da {e.escala?.nome}</Titulo>
              <Tabela
                cores={[]}
                discursivas={Model.discursivas}
                linhas={e.tabela}
                mediaGeral={CalcularMediaGeral(e.tabela)}
                recomendacoes={[]}
                temColuna={TemColuna}
                priorizadas={Model.priorizadas}
                onPriorizar={(e) => setModel({ ...Model, priorizadas: e })}
                apenasAutoAvaliacao={false}
              />
              <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                {e.escala?.respostas.map((e, i) => {
                  return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                })}
              </Flex>
            </Flex>)
            })}
            {Model.tabelaPDI && <Flex flexDir={'column'} gap={'.5rem'}>
              <Flex alignItems={'center'} gap={'.5rem'}>
                <Titulo>Tabela das competências trabalhadas no último PDI do colaborador</Titulo>
              </Flex>
              <TabelaPDI
                discursivas={Model.discursivas}
                linhas={Model.tabelaPDI}
                temColuna={TemColuna}
                priorizadas={Model.priorizadas}
                onPriorizar={(e) => setModel({ ...Model, priorizadas: e })}
              />
              <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                {Model.escalaPDI?.respostas.map((e, i) => {
                  return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                })}
              </Flex>
            </Flex>}
          </Flex>

          <Flex mt={'.75rem'} justifyContent={'end'} gap={'.5rem'}>
            <ButtonCmp VarColor='c6' onClick={() => setModoEdicaoPrioridades(false)}>Voltar</ButtonCmp>
            <ButtonCmp VarColor='Green1' rightIcon={<FaSave />} onClick={SalvarAlteracoes}>Salvar alterações</ButtonCmp>
          </Flex>
        </WhiteContainer>
      }

      {Model.mudancaEixo && <Alert marginTop={'1rem'} borderRadius={'8px'} justifyContent='space-between' status='warning'>
        <Flex flexDir={'column'}>
          <Flex>
            <AlertIcon color={'#FF9900'} />
            <AlertTitle>OBSERVAÇÃO: </AlertTitle>
          </Flex>
          <AlertDescription>Os eixos de Pares, Liderados e Alta Liderança, que não atingiram o mínimo de 3 avaliadores cada, foram agrupados em 'Outros' devido a regra do sistema para evitar rastreamento das respostas.</AlertDescription>
        </Flex>
      </Alert>}
    </Body>
  )
}
